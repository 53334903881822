import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <main className="flex flex-col flex-1 mx-auto w-full">{children}</main>

      <footer className="bg-blue-700">
        <nav className="flex justify-between mx-auto p-4 md:p-8 text-sm">
          <p className="text-white">© 2019 TREC Dental Consulting</p>

          <p>
            <a
              className="font-bold no-underline text-white"
              href="mailto:info@trecdental.com"
            >
              info@trecdental.com
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
