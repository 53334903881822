import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-white">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4 md:p-8">
        <Link className="flex items-center no-underline" to="/">
          <svg
            className="fill-current h-12 mr-2 w-12"
            height="53"
            viewBox="0 0 399 401"
            width="399"
            xmlns="http://www.w3.org/2000/svg"
          >
            <linearGradient
              id="a"
              x1="0%"
              x2="100%"
              y1="50.001777%"
              y2="50.001777%"
            >
              <stop offset="0" stop-color="#ed1c24" />
              <stop offset="1" stop-color="#be1e2d" />
            </linearGradient>
            <linearGradient
              id="b"
              x1=".023334%"
              x2="100.004887%"
              y1="50.002607%"
              y2="50.002607%"
            >
              <stop offset="0" stop-color="#356eb6" />
              <stop offset=".3" stop-color="#275f9e" />
              <stop offset=".94" stop-color="#043762" />
              <stop offset="1" stop-color="#00335c" />
            </linearGradient>
            <linearGradient
              id="c"
              x1="0%"
              x2="100%"
              y1="50.004532%"
              y2="50.004532%"
            >
              <stop offset="0" stop-color="#a6ba33" />
              <stop offset="1" stop-color="#006838" />
            </linearGradient>
            <linearGradient
              id="d"
              x1=".01144%"
              x2="100.011163%"
              y1="49.99784%"
              y2="49.99784%"
            >
              <stop offset=".12" stop-color="#faaf42" />
              <stop offset=".69" stop-color="#f4752c" />
              <stop offset="1" stop-color="#f15a22" />
            </linearGradient>
            <g fill="none" transform="translate(-1 -1)">
              <path
                d="m319.48 180.72-1.34-68.64c-224.82-237.96-229.8-19.58-229.8-19.58 13.92-65.8 74.45-40.8 130.06-.89-1.797239-.2312468-3.607982-.341495-5.42-.33-21.118838.4181562-37.900843 17.875919-37.485394 38.994811.41545 21.118891 17.871062 37.903134 38.990007 37.49039 21.118944-.412743 37.905424-17.866204 37.495387-38.985201-.098698-4.555417-1.0237-9.055061-2.73-13.28 24.592196 20.43123 48.03786 42.20435 70.23 65.22z"
                fill="url(#a)"
              />
              <path
                d="m304.92 89.5c66.4 10.66 44.39 72.35 7.28 129.85.146871-1.796439.163582-3.60115.05-5.4-1.069727-16.790056-12.939018-30.931113-29.289403-34.895355-16.350385-3.964241-33.378128 3.17057-42.01872 17.606306-8.640593 14.435735-6.883897 32.814081 4.334932 45.351552 11.218829 12.53747 29.289855 16.317362 44.593191 9.327497-19.206592 25.580546-39.814798 50.078038-61.73 73.38l68.47-4.71c226.69-236.24 8.31-230.51 8.31-230.51z"
                fill="url(#b)"
              />
              <path
                d="m181.85 309.96c1.789806.280433 3.598367.424182 5.41.43 16.767652.082075 31.642353-10.744231 36.718802-26.725174 5.07645-15.980944-.823846-33.40656-14.565119-43.015843-13.741274-9.609282-32.133497-9.171441-45.401952 1.080828-13.268454 10.252269-18.332876 27.938909-12.501731 43.660189-24.135237-20.955819-47.093596-43.2298-68.77-66.72l-.16 68.63c219.56 242.83 229.31 24.6 229.31 24.6-15.34 65.47-75.3 39.17-130.04-1.94z"
                fill="url(#c)"
              />
              <path
                d="m180.14 79.74-68.62 1.57c-237.23 225.59-18.8 229.86-18.8 229.86-65.84-13.7-41.06-74.29-1.33-130.05-.2334226 1.8001-.3303658 3.615277-.29 5.43.4972144 21.106564 18.009482 37.814334 39.116093 37.319135 21.106612-.495198 37.816054-18.00587 37.322871-39.112529-.493182-21.106659-18.002258-37.817773-39.108964-37.326606-4.549705.109234-9.04209 1.040913-13.26 2.75 20.34055-24.660636 42.030386-48.176598 64.97-70.44z"
                fill="url(#d)"
              />
            </g>
          </svg>
          <span className="text-xl tracking-tight text-gray-800">
            {site.siteMetadata.title}
          </span>
        </Link>

        <button
          className="block md:hidden border border-black flex items-center px-3 py-2 rounded"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/#expertise`,
              title: `Our Expertise`
            },
            {
              route: `/#team`,
              title: `Our Team`
            },
            {
              route: `/#edge`,
              title: `TREC Edge`
            }
          ].map(link => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
